import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import Resizer from 'react-image-file-resizer';

function FileUploader({ files, setFiles, blockRemove = false }) {
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    setFiles(selectedFiles.map((file) => file.resized));
  }, [selectedFiles]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files);

    // Filtrer les fichiers déjà ajoutés
    const newFiles = files.filter((file) => !selectedFiles.some((f) => f.name === file.name));
    const resizedFiles = await Promise.all(
      newFiles.map(async (file) => {
        const resized = await resizeFile(file);
        const preview = await resizeFileForPreview(file); // Version réduite pour l'aperçu
        return { ...file, resized, preview };
      })
    );

    setSelectedFiles((prevFiles) => [...prevFiles, ...resizedFiles]);
  };

  const removeFile = (index: number) => {
    const fileToRemove = selectedFiles[index];
    // Libération de l'URL de prévisualisation
    if (fileToRemove.preview) {
      URL.revokeObjectURL(fileToRemove.preview);
    }
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Fonction pour redimensionner les images pour l'upload
  const resizeFile = (file: Blob) =>
    new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        3000, // Largeur max pour l'upload
        3000, // Hauteur max pour l'upload
        'JPEG',
        80, // Qualité
        0, // Rotation
        (uri) => resolve(uri), // Retourne le fichier
        'file',
        undefined
      );
    });

  // Fonction pour redimensionner les images pour l'aperçu
  const resizeFileForPreview = (file: Blob) =>
    new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        200, // Largeur pour l'aperçu
        200, // Hauteur pour l'aperçu
        'JPEG',
        50, // Qualité réduite pour l'aperçu
        0, // Rotation
        (uri) => resolve(URL.createObjectURL(uri as Blob)), // Crée un URL d'aperçu
        'file',
        undefined
      );
    });

  return (
    <div>
      <Button
        disabled={blockRemove}
        onClick={() => {
          document.getElementById('fileInput').click();
        }}
        aria-label="Joindre des fichiers"
      >
        <div>
          <svg width="35" height="38" viewBox="0 0 35 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.5361 34.8895C14.276 39.0547 7.42621 39.0381 3.18982 34.8313C-1.04535 30.6269 -1.06204 23.8236 3.13284 19.5931L3.12931 19.5907L18.5386 4.28958L20.4656 2.37674C23.6572 -0.792246 28.8304 -0.792246 32.0219 2.37674C35.2134 5.54572 35.2134 10.6833 32.0219 13.8535L15.0359 30.5451L15.03 30.5403C12.9367 32.5445 9.60025 32.5303 7.53191 30.4869C5.46358 28.4423 5.45055 25.1463 7.47845 23.0803L7.47248 23.0731L9.36389 21.2067L18.821 11.8659L20.7112 13.7348L9.36389 24.942C8.319 25.9726 8.319 27.6456 9.36389 28.6773C10.4088 29.709 12.103 29.709 13.1467 28.6773L30.0958 11.9382L30.091 11.9347L30.1552 11.88C32.283 9.76773 32.283 6.34109 30.1552 4.22893C28.0275 2.11677 24.5795 2.11664 22.4518 4.22893L22.3948 4.29311L22.3912 4.29067L20.4654 6.20229L5.05518 21.5035C1.86367 24.6725 1.86367 29.8101 5.05518 32.9791C8.24668 36.1481 13.4212 36.1481 16.6102 32.9791L30.0936 19.5907L32.0195 17.6791L33.9454 19.5907L32.0195 21.5035L18.5386 34.8919L18.5361 34.8895Z"
              fill={blockRemove ? '#CCCCCC' : '#F17633'}
            />
          </svg>
          <span style={{ position: 'relative', top: '-10px' }}>Joindre des fichiers</span>
        </div>
      </Button>
      <input
        type="file"
        id="fileInput"
        accept="image/*"
        style={{ display: 'none' }}
        multiple
        onChange={handleFileChange}
      />
      <div className="preview-container" style={styles.previewContainer}>
        {selectedFiles.map((file, index) => (
          <div key={index} className="preview-item" style={styles.previewItem}>
            {!blockRemove && (
              <CancelIcon
                onClick={() => removeFile(index)}
                sx={{ cursor: 'pointer', top: '5px', right: '5px', position: 'absolute', margin: 0, padding: 0 }}
              />
            )}
            <img src={file.preview} alt={file.name} style={styles.image} />
          </div>
        ))}
      </div>
    </div>
  );
}

// Styles CSS en JS
const styles = {
  previewContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap' as 'wrap',
    gap: '10px',
    marginTop: '20px'
  },
  previewItem: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    border: '1px solid #ccc',
    padding: '10px',
    borderRadius: '5px',
    width: '150px',
    position: 'relative' as 'relative',
    transition: 'background-color 0.3s'
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100px',
    objectFit: 'contain' as 'contain'
  }
};

export default FileUploader;
