import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = ["Identification", "Localisation", "Documents", "Validation"];
const stepsWithoutLocalisation = ["Identification", "Documents", "Validation"];

const HorizontalStepper = ({ step, hasMatterPortScan }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={step} alternativeLabel>
        {hasMatterPortScan !== undefined && hasMatterPortScan.model_id !== undefined && hasMatterPortScan.model_id !== ''
          ? steps.map((label) => (
              <Step key={label}>
                <StepLabel style={{ fontSize: "10px" }}>{label}</StepLabel>
              </Step>
            ))
          : stepsWithoutLocalisation.map((label) => (
              <Step key={label}>
                <StepLabel style={{ fontSize: "10px" }}>{label}</StepLabel>
              </Step>
            ))}
      </Stepper>
    </Box>
  );
}

export default HorizontalStepper;
