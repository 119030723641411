import { IconButton, SvgIcon, Tooltip, Zoom, useMediaQuery } from '@mui/material';
import { renderToolViewerIcons } from '../../../../utils/references';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

export const ViewerIcon = ({
  isActive,
  iconId,
  iconName,
  setActivePanel,
  layoutWithPannel,
  context = null,
  titleIcon = false
}) => {
  const theme = useTheme();
  const [isActiveIcon, setIsActiveIcon] = useState(false);
  const matchesSm = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (isActive === iconId) {
      setIsActiveIcon(true);
    } else {
      setIsActiveIcon(false);
    }
  }, [isActive]);
  return (
    <Tooltip title={iconName} arrow placement={layoutWithPannel ? 'left' : 'bottom'} TransitionComponent={Zoom}>
      <IconButton
        onClick={() => setActivePanel(iconId)}
        key={iconId}
        sx={
          layoutWithPannel
            ? {
                padding: 0,
                margin: 0,
                position: 'relative',
                left: 0,
                paddingTop: '10px',
                paddingBottom: titleIcon ? '15px' : '10px',
                maxWidth: '70px',
                borderRadius: 0,
                pointerEvents: isActiveIcon ? 'none' : 'auto',
                fill: isActiveIcon ? '#062746' : 'white',
                backgroundColor: !titleIcon ? (isActiveIcon ? 'white' : null) : 'rbga(255, 0, 0, 1)',
                '&:hover': {
                  backgroundColor: '#dbcec2',
                  borderRadius: 0,
                  fill: isActiveIcon ? '#062746' : '#062746'
                }
                // fontSize: matchesSm ? '24px' : '20px'
              }
            : {
                borderRadius: 0,
                pointerEvents: isActiveIcon ? 'none' : 'auto',
                fill: isActiveIcon ? '#062746' : 'white',
                backgroundColor: !titleIcon ? (isActiveIcon ? 'white' : null) : 'rbga(255, 0, 0, 1)',
                '&:hover': {
                  backgroundColor: !titleIcon ? '#dbcec2' : 'rbga(255, 0, 0, 1)',
                  borderRadius: 0,
                  fill: isActiveIcon ? '#062746' : '#062746'
                }
                // fontSize: matchesSm ? '24px' : '20px'
              }
        }
      >
        <SvgIcon>{renderToolViewerIcons(iconId)}</SvgIcon>
      </IconButton>
    </Tooltip>
  );
};
