import { Box, Grid } from '@mui/material';
import { useEffect, useRef } from 'react';
import RegisConversationItem from './RegisConversationItem';
import ChatQuestion from './ChatQuestion';

const ChatBot = ({
  allDatas,
  width,
  chatTree,
  answerTree,
  validateAnswer,
  setMatterportFrameVisible,
  setOpenModalUploadFile,
  setOpenModalTutorial,
  tags,
  setTags,
  hasMatterportScan,
  blockAllAnswers,
  setBlockAllAnswers,
  files,
  setFiles,
  sendDatas,
  setTagMode,
  setRemoveAllTags,
  setNavigateToTag,
  choosenContact,
  blockRemove,
  setBlockRemove
}) => {
  const chatContainer = useRef(null);
  useEffect(() => {
    // console.log(allDatas);
    scrollToBottom();
  }, [answerTree]);

  useEffect(() => {
    scrollToBottom();
  }, [tags]);

  const scrollToBottom = () => {
    chatContainer.current.scrollTo(30000, chatContainer.current.scrollHeight);
  };

  useEffect(() => {
    // console.log(allDatas);
  }, []);

  useEffect(() => {
    // console.log('answerTree', answerTree);
  }, [answerTree]);

  return (
    <Grid container direction="column">
      <Grid
        item
        style={{ padding: '10px', backgroundColor: 'white', textAlign: 'center', fontWeight: '500', fontSize: '26px' }}
      >
        {allDatas?.organization?.name}
      </Grid>
      <Grid item xs>
        <Box
          sx={{
            backgroundColor: 'white',
            padding: '10px',
            paddingBottom: '50px',
            position: 'absolute',
            height: 'calc(100dvh - 110px)',
            overflowX: 'auto',
            width: `calc(${width}px - 20px)`,
            fontSize: '18px'
          }}
          ref={chatContainer}
        >
          {answerTree &&
            answerTree.map((answer, index) => {
              // console.log(index);
              // console.log(answerTree.length - 1);
              return answer ? (
                <ChatQuestion
                  key={index}
                  questionDatas={answer}
                  answerTree={answerTree}
                  scrollToBottom={scrollToBottom}
                  validateAnswer={validateAnswer}
                  setMatterportFrameVisible={setMatterportFrameVisible}
                  allDatas={allDatas}
                  setOpenModalUploadFile={setOpenModalUploadFile}
                  setOpenModalTutorial={setOpenModalTutorial}
                  tags={tags}
                  setTags={setTags}
                  hasMatterportScan={hasMatterportScan}
                  blockAllAnswers={blockAllAnswers}
                  setBlockAllAnswers={setBlockAllAnswers}
                  files={files}
                  setFiles={setFiles}
                  sendDatas={sendDatas}
                  setTagMode={setTagMode}
                  setRemoveAllTags={setRemoveAllTags}
                  setNavigateToTag={setNavigateToTag}
                  width={width}
                  choosenContact={choosenContact}
                  blockRemove={blockRemove}
                  setBlockRemove={setBlockRemove}
                ></ChatQuestion>
              ) : null;
            })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChatBot;
