import { Button, Grid, IconButton, SvgIcon } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ForwardIcon from '@mui/icons-material/Forward';

const MatterportViewer = ({
  pannelWidth,
  matterportFrameVisible,
  allDatas,
  matterportIframeWidth,
  validateTags,
  tags = null,
  setTags = null
}) => {
  const { REACT_APP_MATTERPORT_KEY } = process.env;
  const circleIndicator = useRef(null);
  const circleIndicatorFill = useRef(null);
  const circleIndicatorGrey = useRef(null);
  const circleIndicatorBackGround = useRef(null);

  const startTagging = useRef(false);

  const startTaggingCoords = useRef({ x: 0, y: 0 });
  const taggingTolerance = 20;

  const matterPortIFrame = useRef<HTMLIFrameElement>(null);

  const delayBeforeAddMatterTag = 600;

  const [iframeSrc, setIframeSrc] = useState(null);
  const [mpSdk, setMpSDK] = useState(null);

  const intersectionData = useRef(null);
  const [intersectionDataSelected, setIntersectionDataSelected] = useState(null);

  const interval = useRef(null);
  const timeout = useRef(null);

  const placeTagMode = useRef(false);

  const [sidTagSelected, setSidTagSelected] = useState(null);

  const stemHeightScale = 0.15;

  //   const validateTags = () => {
  //     console.log(tags);
  //   };

  useEffect(() => {
    console.log('matterportFrameVisible', matterportFrameVisible);
    placeTagMode.current = matterportFrameVisible;
  }, [matterportFrameVisible]);

  const onShowcaseConnected = async (sdk, iframe) => {
    console.log(sdk);
    console.log(iframe);

    const bodyIframe = iframe.contentWindow.document.body;
    bodyIframe.addEventListener('mousedown', iDown);
    bodyIframe.addEventListener('mousemove', stopLongClick);
    bodyIframe.addEventListener('mouseup', stopLongClick);
    bodyIframe.addEventListener('touchstart', iDown);
    bodyIframe.addEventListener('touchmove', stopLongClick);
    bodyIframe.addEventListener('touchend', stopLongClick);
  };

  const iDown = (e) => {
    console.log(matterportFrameVisible);
    console.log(placeTagMode.current);
    if (placeTagMode.current === false) return;
    console.log('iDown');

    const pathLength = circleIndicatorFill.current.getTotalLength();
    const pathLengthGrey = circleIndicatorGrey.current.getTotalLength();

    circleIndicatorFill.current.setAttribute('stroke-dashoffset', pathLength);
    circleIndicatorFill.current.setAttribute('stroke-dasharray', pathLength);
    circleIndicatorGrey.current.setAttribute('stroke-dashoffset', pathLengthGrey);
    circleIndicatorGrey.current.setAttribute('stroke-dasharray', pathLengthGrey);

    if (e.targetTouches && e.targetTouches.length > 0) {
      if (e.targetTouches.length > 1) {
        return;
      }
      circleIndicator.current.style.left = `${
        e.targetTouches[0].clientX - 125 //+ node.offsetParent.getBoundingClientRect().left
      }px`;
      circleIndicator.current.style.top = `${
        e.targetTouches[0].clientY - 125 //+ node.offsetParent.getBoundingClientRect().top
      }px`;
      startTaggingCoords.current = { x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY };
    } else {
      circleIndicator.current.style.left = `${e.clientX - 125}px`;
      circleIndicator.current.style.top = `${
        e.clientY - 125 //+ node.offsetParent.getBoundingClientRect().top
      }px`;
      startTaggingCoords.current = { x: e.clientX, y: e.clientY };
    }

    circleIndicatorBackGround.current.setAttribute('fill', 'white');
    circleIndicatorBackGround.current.setAttribute('opacity', '.1');
    circleIndicatorGrey.current.setAttribute('visibility', 'hidden');

    timeout.current = setTimeout(() => {
      console.log('setTimeout');
      startTagging.current = true;
      gsap.fromTo('#circleIndicator', { scale: 0 }, { scale: 0.5, duration: 0.3, ease: 'expo.out' }).then(() => {});
      const startIntervalTime = new Date().getTime();
      interval.current = setInterval(() => {
        // console.log('interval', new Date().getTime() - startIntervalTime);

        const pcent = (new Date().getTime() - startIntervalTime) / delayBeforeAddMatterTag;

        if (new Date().getTime() - startIntervalTime >= delayBeforeAddMatterTag) {
          setIntersectionDataSelected(JSON.parse(JSON.stringify(intersectionData.current)));
          console.log(intersectionData.current.position);
          placeTagComplete(e);
        } else {
          circleIndicatorFill.current.setAttribute('stroke-dashoffset', pathLength - pathLength * pcent);
          circleIndicatorGrey.current.setAttribute('stroke-dashoffset', pathLengthGrey - pathLengthGrey * pcent);
        }
      }, 16);
      circleIndicator.current.setAttribute('visibility', 'visible');
      circleIndicatorGrey.current.setAttribute('visibility', 'visible');
    }, 300);
  };

  const placeTagComplete = (e) => {
    console.log('placeTagComplete');
    clearInterval(interval.current);
    circleIndicatorBackGround.current.setAttribute('fill', '#F17633');
    circleIndicatorBackGround.current.setAttribute('opacity', '1');

    // gsap.to('#circleIndicatorBackGround', { fill: 'red', duration: 1, ease: 'elastic.out(1,0.3)' });
    // circleIndicatorGrey.current.setAttribute('visibility', 'visible');
    gsap.to('#circleIndicator', { scale: 0.02, duration: 0.5, ease: 'elastic.out(1,0.3)' }).then(() => {
      gsap.to('#circleIndicator', { scale: 0, duration: 0.2, ease: 'expo.in' }).then(() => {
        stopLongClick(e);

        clearTimeout(timeout.current);
        clearInterval(interval.current);
        circleIndicator.current.setAttribute('visibility', 'hidden');
        circleIndicatorGrey.current.setAttribute('visibility', 'hidden');
        circleIndicatorGrey.current.setAttribute('visibility', 'hidden');
      });
    });
    circleIndicatorFill.current.setAttribute('stroke-dashoffset', 0);
    circleIndicatorGrey.current.setAttribute('stroke-dashoffset', 0);
  };

  useEffect(() => {
    if (intersectionDataSelected) {
      console.log('intersectionDataSelected', intersectionDataSelected);
      const scale = stemHeightScale;
      mpSdk.Tag.add({
        anchorPosition: intersectionDataSelected.position,
        stemVector: {
          x: scale * intersectionDataSelected.normal.x,
          y: scale * intersectionDataSelected.normal.y,
          z: scale * intersectionDataSelected.normal.z
        },
        color: { r: 241 / 255, g: 118 / 255, b: 51 / 255 }
      }).then((tag) => {
        setTags([
          ...tags,
          { id: tag[0], position: intersectionDataSelected.position, normal: intersectionDataSelected.normal }
        ]);
        console.log(tag);
        mpSdk.Tag.allowAction(tag[0], {
          navigating: true
        });

        var obj = { scale: -0.3 };
        var obj2 = { opacity: 0 };

        gsap.to(obj, 1, {
          scale: stemHeightScale,
          onUpdate: (o) => {
            // console.log(o.scale);
            mpSdk.Tag.editStem(tag[0], { stemHeight: o.scale });
          },
          onUpdateParams: [obj],
          ease: 'elastic.out(1,0.3)'
        });

        gsap.to(obj2, 0.5, {
          opacity: 1,
          onUpdate: (o) => {
            // console.log(o);
            // console.log(o.opacity);
            mpSdk.Tag.editOpacity(tag[0], o.opacity);
          },
          onUpdateParams: [obj2]
        });
      });
    }
  }, [intersectionDataSelected]);

  useEffect(() => {
    if (mpSdk) {
      mpSdk.Pointer.intersection.subscribe((intersection) => {
        // console.log(intersection.position, intersection.normal);
        intersectionData.current = intersection;
      });

      mpSdk.Tag.openTags.subscribe({
        prevState: {
          hovered: null,
          docked: null,
          selected: null
        },
        onChanged(newState) {
          if (newState.hovered !== this.prevState.hovered) {
            if (newState.hovered) {
              console.log(newState.hovered, 'was hovered');
            } else {
              console.log(this.prevState.hovered, 'is no longer hovered');
            }
          }
          if (newState.docked !== this.prevState.docked) {
            if (newState.docked) {
              console.log(newState.docked, 'was docked');
            } else {
              console.log(this.prevState.docked, 'was undocked');
            }
          }

          // only compare the first 'selected' since only one tag is currently supported
          const [selected = null] = newState.selected; // destructure and coerce the first Set element to null
          if (selected !== this.prevState.selected) {
            if (selected) {
              console.log(selected, 'was selected');
              setSidTagSelected(selected);
            } else {
              console.log(this.prevState.selected, 'was deselected');
              setSidTagSelected(null);
            }
          }

          // clone and store the new state
          this.prevState = {
            ...newState,
            selected
          };
        }
      });
    }
  }, [mpSdk]);

  //   '&help=0',
  //   '&hr=0',
  //   '&views=0',
  //   '&gt=0',
  //   '&dh=0',
  //   '&tagNav=0',
  //   '&search=0',
  //   '&brand=0',
  //   '&mls=2',
  //   '&play=1',
  //   '&lang=fr',
  //   '&vr=0',
  //   '&qs=1',
  //   `&applicationKey=${REACT_APP_MATTERPORT_KEY}`

  useEffect(() => {
    const params = [
      '&help=0',
      //  0	 Default	Do not show help at all.
      //  1	 	        Always show help when the Matterport model opens.
      //  2	 	        Always show help when the Matterport model opens. More concise help text than &help=1.
      '&nt=0',
      //  0  Default	Only for mobile. If a Matterport model is embedded on a webpage, then when user presses the play button 3D Showcase opens in the same browser tab.
      //  1	 	        Only for mobile. If a Matterport model is embedded on a webpage, then when user presses the play button, 3D Showcase opens in a new browser tab.
      '&play=1',
      //  0  Default	Shows a Play button ▶️️  on the iframe. The user must press the Play button ▶️️  to open the Matterport model.
      //  1	 	        Automatically opens the Matterport model when the iframe loads on the page. If the 3D Showcase is opened via a link (not embedded in an iframe), this option is chosen automatically. When embedding Showcase within an iframe, play=1 will only work if the iframe is large enough on the user’s screen to provide a good user experience. Specifically, on mobile devices, the iframe has to cover at least 60% of the screen.
      '&qs=1',
      //  0  Default	Disable Quickstart (when the Matterport model first opens, zoom in from Dollhouse View).
      //  1	 	        Enable Quickstart (when the Matterport model first opens, go straight into Inside View). Learn more. Only works if Start Position is Inside View.
      '&brand=0',
      //  0 	 	    Hide 'Presented By' details when model opens. Hide contact details in the "About" panel (top-left grey corner of 3D Showcase).
      //  1	 Default	Display all branding information in the "About" panel (top-left corner of 3D Showcase).
      '&dh=0',
      //  0	 	        Hide Dollhouse View in introductory fly-in. Hide Dollhouse Button in the bottom left corner of the 3D Showcase UI. Hides Dollhouse and Floorplan view snapshots in the highlight reel.
      //  1	 Default	Show Dollhouse View
      '&tour=1',
      //  0	 	        No tour controls or highlight reel appear.
      //  1	 Default	The tour mode saved in the model is used.
      //  2	 	        Story mode tour is used.
      //  3	 	        Highlight reel mode tour is used.
      '&gt=0',
      //  0             Hide Guided Tour buttons in the bottom left corner of the 3D Showcase UI
      //  1	 Default	Show Guided Tour buttons
      '&views=0',
      //  0	 	        Hide Views from the 3D Showcase UI
      //  1	 Default	Show Views
      '&hr=0',
      //  0	 	        Hide highlight reel at the bottom of the 3D Showcase UI
      //  1	 Default	Show highlight reel
      '&mls=2',
      //  0  Default	Show branding information, links in Tags, VR, the Business Card, and the Quick Link.
      //  1	 	        Show the MLS-friendly version of the Matterport model. This removes branding, removes links in Tags, removes VR, removes the Business Card, and removes the Quick Link.
      //  2	 	        Same behavior as &mls=1 but also removes the "About" panel in the top-left corner after loading.
      '&mt=1',
      //  0	 	        Hide Mattertags and "Mattertag™ Content" toggle in the "About" panel
      //  1	 Default 	Show Mattertag™ Posts
      '&tagNav=0',
      //  0	 	        Hides the < and > buttons from Tags and disables navigating through Tags using the "," and "." keys.
      //  1	 Default 	Shows the < and > buttons for Tags and enables navigating through Tags using the "," and "." keys.
      '&pin=1',
      //  0	 	        Hide placed 360º Views pins in Dollhouse and Floor Plan.
      //  1	 Default 	Show placed 360º Views pins in Dollhouse and Floor Plan
      '&portal=1',
      //  0	 	        Hide placed 360º Views connection portals while in Inside View
      //  1	 Default 	Show placed 360º Views connection portals while in Inside View
      '&f=1',
      //  0 	 	    Remove the option to switch floors. This treats the Matterport model as "all one floor".
      //  1	 Default	Let the user navigate the 3D model floor by floor.
      '&fp=1',
      //  0 	 	    Remove the floor plan view button from the bottom-left corner of the 3D Showcase UI.
      //  1	 Default	Show the floor plan view button in the bottom-left corner of the 3D Showcase UI.
      '&lang=fr',
      '&nozoom=0',
      //  0  Default	Enable zooming in 3D Showcase
      //  1	 	        Disable zooming in 3D Showcase
      '&search=0',
      //  0 	 	    Remove the magnifying glass (search) icon from the upper-left corner of the 3D Showcase UI.
      //  1	 Default	Show the magnifying glass (search) icon in the upper-left corner of the 3D Showcase UI. If Space Search is disabled at the account level, this parameter has no effect.
      '&wh=1',
      //  0	 	        Ignore scroll wheel input from the mouse. Scroll wheel input will cause the entire webpage to move up and down. This parameter is only valid when the model is embedded with an iframe.
      //  1  Default	Enables scroll wheel input from the mouse, so the 3D model can zoom in. 3D Showcase will only listen to scroll wheel input if the cursor is over the iframe.
      '&kb=1',
      //  0 	 	    Do not pan when you reach a new highlight in a Guided Tour.
      //  1	 Default	Gently pan once you reach a new highlight in a Guided Tour.
      '&lp=0',
      //  0  Default	Stop once you reach the end of the Guided Tour.
      //  1	 	        Loop back to the beginning once you reach the end.
      '&title=1',
      //  0 	 	    Space title is not displayed. Top-left about panel not displayed.
      //  1	 Default	Space title is displayed. Top-left about panel is displayed.
      '&tourcta=1',
      //  0 	 	    No call to action at the end of a a Guided Tour
      //  1	 Default	Large call to action at the end of a Guided Tour
      //  2	 	        Small call to action at the end of a Guided Tour
      '&vr=0',
      `&applicationKey=${REACT_APP_MATTERPORT_KEY}`
    ];

    if (allDatas) {
      setIframeSrc(`../../matterport/showcase.html?m=${allDatas.property.matterport_scan.model_id}${params.join('')}`);

      matterPortIFrame.current.addEventListener('load', async (e) => {
        try {
          const mpSdk = await (e.target as HTMLIFrameElement).contentWindow['MP_SDK'].connect(
            matterPortIFrame.current,
            REACT_APP_MATTERPORT_KEY
          );
          setMpSDK(mpSdk);
          console.log(mpSdk);
          onShowcaseConnected(mpSdk, matterPortIFrame.current);
        } catch (e) {}
      });
    }
  }, [allDatas]);

  const stopLongClick = (e) => {
    console.log(e.type);
    if (startTagging.current && e.type !== 'touchend' && e.type !== 'mouseup') {
      if (e.targetTouches && e.targetTouches.length > 0) {
        if (
          e.targetTouches[0].clientX < startTaggingCoords.current.x - taggingTolerance ||
          e.targetTouches[0].clientX > startTaggingCoords.current.x + taggingTolerance ||
          e.targetTouches[0].clientY < startTaggingCoords.current.y - taggingTolerance ||
          e.targetTouches[0].clientY > startTaggingCoords.current.y + taggingTolerance
        ) {
          console.log('stopLongClick', startTaggingCoords.current.x, e.targetTouches[0].clientX);
          clearTimeout(timeout.current);
          if (startTagging.current) {
            console.log('stopLongClick');
            startTagging.current = false;
            circleIndicator.current.setAttribute('visibility', 'hidden');
            circleIndicatorGrey.current.setAttribute('visibility', 'hidden');
            clearInterval(interval.current);
            gsap.to('#circleIndicator', { scale: 0, duration: 0.5 }).then(() => {
              circleIndicator.current.setAttribute('visibility', 'hidden');
              circleIndicatorGrey.current.setAttribute('visibility', 'hidden');
            });
          }
        }
      } else {
        if (
          e.clientX < startTaggingCoords.current.x - taggingTolerance ||
          e.clientX > startTaggingCoords.current.x + taggingTolerance ||
          e.clientY < startTaggingCoords.current.y - taggingTolerance ||
          e.clientY > startTaggingCoords.current.y + taggingTolerance
        ) {
          console.log('stopLongClick', startTaggingCoords.current.x, e.clientX);
          clearTimeout(timeout.current);
          if (startTagging.current) {
            console.log('stopLongClick');
            startTagging.current = false;
            circleIndicator.current.setAttribute('visibility', 'hidden');
            circleIndicatorGrey.current.setAttribute('visibility', 'hidden');
            clearInterval(interval.current);
            gsap.to('#circleIndicator', { scale: 0, duration: 0.5 }).then(() => {
              circleIndicator.current.setAttribute('visibility', 'hidden');
              circleIndicatorGrey.current.setAttribute('visibility', 'hidden');
            });
          }
        }
      }
    } else {
      clearTimeout(timeout.current);
      clearInterval(interval.current);
      startTagging.current = false;
      circleIndicator.current.setAttribute('visibility', 'hidden');
      circleIndicatorGrey.current.setAttribute('visibility', 'hidden');
    }
  };

  return (
    <Grid item id="matterportContainer" xs style={{ backgroundColor: 'rgb(34,34,34)' }}>
      <div
        style={{
          height: '100dvh',
          width: pannelWidth === window.innerWidth ? '100%' : 'auto',
          position: pannelWidth === window.innerWidth ? 'fixed' : 'relative',
          visibility: pannelWidth !== window.innerWidth ? 'visible' : matterportFrameVisible ? 'visible' : 'hidden',
          zIndex: 100
        }}
      >
        <iframe
          style={{
            border: 'none',
            height: '100dvh',
            width: '100%',
            visibility: pannelWidth !== window.innerWidth ? 'visible' : matterportFrameVisible ? 'visible' : 'hidden',
            zIndex: 100
          }}
          src={iframeSrc}
          ref={matterPortIFrame}
        ></iframe>

        <div
          style={{
            position: 'fixed',
            zIndex: 102,
            bottom: '30px',
            display: 'flex',
            left:
              pannelWidth !== window.innerWidth ? matterportIframeWidth / 2 - 12 + 'px' : pannelWidth / 2 - 12 + 'px'
          }}
        >
          <div
            style={{
              backgroundColor: sidTagSelected ? '#F17633' : 'grey',
              borderRadius: '20px',
              width: '30px',
              height: '30px',
              textAlign: 'center',
              border: 'solid white 2px',
              margin: '2px',
              display: !sidTagSelected ? 'none' : 'block',
              cursor: 'pointer'
            }}
          >
            {/* <IconButton variant="" sx={{ backgroundColor: 'red', borderRadius: '2Ppx' }}> */}
            <SvgIcon
              fontSize="large"
              sx={{ color: 'white', position: 'relative', top: '1px', fontSize: '26px' }}
              onClick={() => {
                mpSdk.Tag.remove(sidTagSelected);
                setSidTagSelected(null);
                console.log(tags);
                setTags(tags.filter((tag) => tag.id !== sidTagSelected));
              }}
            >
              <DeleteForeverIcon fontSize="large"></DeleteForeverIcon>
            </SvgIcon>
            {/* </IconButton> */}
          </div>
          <div
            style={{
              backgroundColor: tags?.length > 0 ? '#F17633' : 'grey',
              borderRadius: '20px',
              width: '30px',
              height: '30px',
              textAlign: 'center',
              border: 'solid white 2px',
              margin: '2px',
              display: tags?.length > 0 && pannelWidth === window.innerWidth ? 'block' : 'none',
              cursor: 'pointer'
            }}
          >
            {/* <IconButton variant="" sx={{ backgroundColor: 'red', borderRadius: '2Ppx' }}> */}
            <SvgIcon
              fontSize="large"
              sx={{ color: 'white', position: 'relative', top: '2px', fontSize: '26px' }}
              onClick={() => {
                validateTags(tags);
              }}
            >
              <ForwardIcon fontSize="large"></ForwardIcon>
            </SvgIcon>
            {/* </IconButton> */}
          </div>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          ref={circleIndicator}
          id="circleIndicator"
          version="1.1"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            pointerEvents: 'none',
            transform: 'rotate(-90deg)',
            zIndex: 1000
          }}
          width="250"
          height={250}
          visibility="hidden"
        >
          <circle cx="125" cy="125" r="108" stroke="grey" strokeWidth="26" fill="none" />
          <circle
            ref={circleIndicatorGrey}
            cx="125"
            cy="125"
            r="108"
            stroke="rgb(21, 41, 68)"
            strokeWidth="24"
            fill="none"
            visibility="hidden"
          />
          <circle ref={circleIndicatorBackGround} cx="125" cy="125" r="98" strokeWidth="0" fill="white" opacity={0.1} />
          <circle
            ref={circleIndicatorFill}
            cx="125"
            cy="125"
            r="108"
            stroke="#F17633"
            strokeWidth="20"
            fill="none"
            strokeDasharray={450}
            strokeDashoffset={450}
          />
        </svg>
      </div>
    </Grid>
  );
};
export default MatterportViewer;
