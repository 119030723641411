import React, { useEffect, useLayoutEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getProduct, axiosClient } from '../../../api';
import HorizontalStepper from '../blocs/HorizontalStepper';
import { useState } from 'react';
import { Button, Grid, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';

// import '../../../css/pico.min.css';
// import '../../../index.css';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Veuillez indiquer votre nom et prénom';
  } else if (values.name.lenght < 3) {
    errors.name = 'Votre nom doit comporter au moins trois lettres';
  }
  if (!values.phone) {
    errors.phone = 'Veuillez indiquer votre numéro de téléphone';
  } else if (values.phone.match(/\d/g).length < 10 || values.phone.match(/\d/g).length > 14) {
    errors.phone = 'Votre numero de téléphone doit être constitué de 10 caractères minimum';
  }
  if (!values.email) {
    errors.email = 'Email requis';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Addresse email invalide';
  }
  if (!values.eventType) {
    errors.eventType = 'Veuillez sélectionner un type d’incident';
  }
  if (!values.desc) {
    errors.desc = 'Veuillez décrire l’incident';
  }
  return errors;
};

export const Form = (props) => {
  let navigate = useNavigate();
  const [errMsg, setErrMsg] = useState({});
  const { idProduct } = useParams();
  const [data, setData] = useState(null);
  const [fullData, setFullData] = useState(null);
  const [uuid_organization, setUuid_organization] = useState(null);
  const [organization, setOrganization] = useState(null);

  const [typeEvent, setTypeEvent] = useState();
  const [desc, setDesc] = useState();
  const [name, setName] = useState(props.declarantInfos.identity);
  const [email, setEmail] = useState(props.declarantInfos.email);
  const [phone, setPhone] = useState(props.declarantInfos.phone);
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });

    console.log(dimensions);
    if (window.innerHeight < window.innerWidth) {
      setIsPortrait(false);
    } else {
      setIsPortrait(true);
    }
  };
  React.useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  useLayoutEffect(() => {
    if (data === null && props.getProductInfos === null) {
      const productType = idProduct.split('-')[0] === 'pro' ? 'product' : 'building';
      console.log(idProduct);
      getProduct(idProduct, productType).then((productData) => {
        axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + productData.jwt;

        setFullData(productData);
        props.setFullData(productData);
        props.setProductInfos(productData.property);
        props.setOrganizationData(productData.organization);
      });
    } else if (props.getProductInfos !== null) {
      setData(props.getProductInfos);
      setUuid_organization(props.getProductInfos.uuid_organization);
      setOrganization(props.getOrganization);
    }
  }, [data, idProduct, props]);

  useEffect(() => {
    console.log(window.innerWidth);
  });

  useEffect(() => {
    console.log('isPortrait', isPortrait);
  }, [isPortrait]);

  useEffect(() => {
    if (fullData === null) {
      setFullData(props.getFullData);
      if (sessionStorage.getItem('av_name')) setName(sessionStorage.getItem('av_name'));
      if (sessionStorage.getItem('av_email')) setEmail(sessionStorage.getItem('av_email'));
      if (sessionStorage.getItem('av_phone')) setPhone(sessionStorage.getItem('av_phone'));
    }
  }, []);

  useEffect(() => {
    if (fullData !== null) {
      setData(fullData.property);
      setUuid_organization(fullData.organization.uuid);
      setOrganization(fullData.organization);
      const emailListHandlers = [];
      fullData.contact.forEach((contact) => {
        emailListHandlers.push(contact.email);
      });

      props.setEmailHandlers(emailListHandlers);
    }
  }, [fullData]);

  const refreshErrors = () => {
    const values = {
      name: name,
      phone: phone,
      email: email,
      eventType: typeEvent,
      desc: desc
    };
    const formValid = validate(values);
    const size = Object.keys(formValid).length;

    if (size !== 0) {
      setErrMsg(validate(values));
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = {
      name: name,
      phone: phone,
      email: email,
      eventType: typeEvent,
      desc: desc
    };
    const formValid = validate(values);
    const size = Object.keys(formValid).length;

    if (size !== 0) {
      setErrMsg(validate(values));
      return;
    }

    const payload = {
      uuid_owner: idProduct,
      uuid_organisation: uuid_organization,
      // title: 'titre',
      desc: values.desc,
      kind: values.eventType,
      data: {
        contact_requestor: {
          name: values.name,
          email: values.email,
          phone: values.phone
        }
      }
    };

    props.setEventPayload(payload);

    props.declarantInfos.identity = values.name;
    props.declarantInfos.email = values.email;
    props.declarantInfos.phone = values.phone;

    sessionStorage.setItem('av_name', values.name);
    sessionStorage.setItem('av_email', values.email);
    sessionStorage.setItem('av_phone', values.phone);

    fullData.property.matterport_scan !== undefined &&
    fullData.property.matterport_scan.model_id !== undefined &&
    fullData.property.matterport_scan.model_id !== ''
      ? navigate('/tagEvent/' + idProduct)
      : navigate('/documentEvent/' + idProduct);
  };

  const Layout = () => {
    return (
      <div>
        {organization && organization.logo && (
          <img
            className="center"
            src={`${document.location.origin}/img/logo_${organization.logo}.png`}
            alt={organization.name}
            width={150}
          />
        )}

        {organization && !organization.logo && <span className="center">{organization.name}</span>}
      </div>
    );
  };

  return (
    <div>
      <Layout />
      {data && (
        <>
          <Grid container spacing={2} sx={{ paddingLeft: '10%', paddingRight: '10%' }}>
            <Grid item xs={12} sm={12}>
              <h3 className="center">
                Déclaration d'incident au :<br />
                {`${data.address.street} ${data.address.zip_code} ${data.address.city}`}
              </h3>
              {fullData && <HorizontalStepper hasMatterPortScan={fullData.property.matterport_scan} step={0} />}
              <hr style={{ marginTop: '1.5em', marginBottom: '1.5em' }} />
            </Grid>
          </Grid>
          <div>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                sx={{
                  paddingLeft: dimensions.width > 900 ? '30%' : '10px',
                  paddingRight: dimensions.width > 900 ? '30%' : '10px'
                }}
              >
                <Grid zeroMinWidth item xs={isPortrait ? 12 : 6} sx={{ textAlign: 'center' }}>
                  <TextField
                    size="small"
                    fullWidth
                    error={errMsg.name ? true : false}
                    id="name"
                    name="name"
                    label="Déclarant"
                    placeholder="Prénom NOM"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                  ></TextField>
                </Grid>

                <Grid zeroMinWidth item xs={isPortrait ? 12 : 6} sx={{ textAlign: 'center' }}>
                  <TextField
                    size="small"
                    fullWidth
                    error={errMsg.phone ? true : false}
                    id="phone"
                    name="phone"
                    label="Téléphone"
                    type="tel"
                    placeholder="06 00 00 00 00"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    value={phone}
                  ></TextField>
                </Grid>

                <Grid zeroMinWidth item xs={isPortrait ? 12 : 6} sx={{ textAlign: 'center' }}>
                  <TextField
                    size="small"
                    fullWidth
                    error={errMsg.email ? true : false}
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="email@exemple.com"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                  ></TextField>
                </Grid>

                <Grid zeroMinWidth item xs={isPortrait ? 12 : 6} sx={{ textAlign: 'center' }}>
                  <FormControl fullWidth>
                    <InputLabel size="small" id="eventType">
                      Type d'incident
                    </InputLabel>
                    <Select
                      size="small"
                      fullWidth
                      labelId="eventType"
                      error={errMsg.eventType ? true : false}
                      id="eventType"
                      name="eventType"
                      // className="combo"
                      // placeholder="Type ... "
                      label="Type d'incident"
                      onChange={(e) => {
                        // refreshErrors();
                        setTypeEvent(Number(e.target.value));
                      }}
                      value={typeEvent}
                      // helperText={errMsg.eventType}
                      sx={{ textAlign: 'left' }}
                    >
                      <MenuItem value={1} sx={{ textAlign: 'left' }}>
                        Dégat des eaux
                      </MenuItem>
                      <MenuItem value={2} sx={{ textAlign: 'left' }}>
                        Panne d'équipement
                      </MenuItem>
                      <MenuItem value={3} sx={{ textAlign: 'left' }}>
                        Autre
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* <select
                    id="eventType"
                    name="eventType"
                    className="combo"
                    onChange={(e) => {
                      setTypeEvent(Number(e.target.value));
                    }}
                    value={typeEvent}
                  >
                    <option value={0}>Type....</option>
                    <option value={1}>Dégat des eaux</option>
                    <option value={2}>Panne d'équipement</option>
                    <option value={3}>Autre</option>
                  </select> */}
                  {/* {errMsg.eventType ? <div className="msg-error animated fadeIn">{errMsg.eventType}</div> : null} */}
                </Grid>

                <Grid item xs={12} sm={12} sx={{ textAlign: 'center' }}>
                  <TextField
                    size="small"
                    error={errMsg.desc ? true : false}
                    fullWidth
                    id="desc"
                    name="desc"
                    multiline
                    rows={2}
                    // label="Description"
                    placeholder="Décrivez votre incident."
                    onChange={(e) => {
                      // refreshErrors();
                      setDesc(e.target.value);
                    }}
                    value={desc}
                  ></TextField>
                  {/* <textarea
                    id="desc"
                    name="desc"
                    rows="5"
                    cols="30"
                    placeholder="Décrivez votre incident."
                    onChange={(e) => {
                      setDesc(e.target.value);
                    }}
                    value={desc}
                  /> */}
                  {/* {errMsg.desc ? <div className="msg-error animated fadeIn">{errMsg.desc}</div> : null} */}
                </Grid>
                <Grid item xs={12} sm={12} sx={{ textAlign: 'center' }}>
                  <Button variant="contained" color="primary" type="submit">
                    {fullData && fullData.property.matterport_scan !== undefined ? (
                      <span>Continuer et localiser</span>
                    ) : (
                      <span>Continuer et ajouter un document</span>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
