import './App.css';
// import { Form } from './pages/declarator/form';
// import { Validate } from './pages/declarator/validate';
// import { useState } from 'react';
// import { Document } from './pages/declarator/document';
import AppRouter from './AppRouter';

/* Theme variables */
import '../theme/variables.css';

import { ThemeProvider } from '@mui/material';
import { appTheme } from '../theme/theme';

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={appTheme}>
        <AppRouter />
      </ThemeProvider>
    </div>
  );
};

export default App;
