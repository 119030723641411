import * as React from 'react';

import { DropzoneArea } from 'react-mui-dropzone';
import Resizer from 'react-image-file-resizer';

export const UploadFile = ({ setFile, files = [] }) => {
  const id_event = document.location.pathname.split('/')[document.location.pathname.split('/').length - 1];

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        3000,
        3000,
        'JPEG',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });

  const handleChangeStatus = async (files) => {
    if (files.length === 0) return;
    console.log(files);
    const imagesResized = [];
    files.forEach(async (f) => {
      console.log(f);
      imagesResized.push(resizeFile(f));
    });
    Promise.all(imagesResized).then((values) => {
      console.log(values);
      setFile(values);
    });
  };

  const getFileAddedMessage = (e) => {
    console.log(e);
    return e + ' à bien été ajouté ';
  };

  const getFileRemovedMessage = (e) => {
    return e + ' à bien été retiré \n';
  };

  return (
    <DropzoneArea
      maxFileSize={8000000}
      onChange={handleChangeStatus}
      acceptedFiles={['image/*']} //  "application/pdf"
      dropzoneText="Souhaitez vous associer des photos ? " // ou des documents
      getFileAddedMessage={getFileAddedMessage}
      getFileRemovedMessage={getFileRemovedMessage}
      showPreviewsInDropzone={true}
      initialFiles={files}
    ></DropzoneArea>
  );
};
