import React from 'react';
import HorizontalStepper from '../blocs/HorizontalStepper';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Button } from '@mui/material';

export const Validate = (props) => {
  let navigate = useNavigate();
  const [organization, setOrganization] = useState(null);
  const { idProduct } = useParams();
  // console.log(props);
  // console.log(props.getEventPayload());
  // console.log(props.getProductInfos)

  console.log(props.getProductInfos);
  // const idProduct = props.getProductInfos.uuid;

  const handleClick = (e) => {
    e.preventDefault();
    navigate('/declareEvent/' + idProduct);
  };

  useEffect(() => {
    if (props.getProductInfos === null) navigate('/declareEvent/' + idProduct);
  }, []);

  useEffect(() => {
    setOrganization(props.getOrganization);
  }, [props]);

  const Layout = () => {
    return (
      <div>
        {organization && organization.logo && (
          <img
            className="center"
            src={`${document.location.origin}/img/logo_${organization.logo}.png`}
            alt={organization.name}
            width={150}
          />
        )}

        {organization && !organization.logo && <span className="center">{organization.name}</span>}
      </div>
    );
  };
  if (props.getProductInfos === null) {
    return;
  }

  return (
    <div className="Validate">
      <Layout />

      <Grid container spacing={2} sx={{ paddingLeft: '10%', paddingRight: '10%' }}>
        <Grid item xs={12} sm={12}>
          <HorizontalStepper
            step={
              props.getProductInfos.matterport_scan !== undefined &&
              props.getProductInfos.matterport_scan.model_id !== undefined &&
              props.getProductInfos.matterport_scan.model_id !== ''
                ? 4
                : 3
            }
            hasMatterPortScan={props.getProductInfos.matterport_scan}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <h4>Incident enregistré</h4>
          <p>
            Nous vous remercions pour votre déclaration.
            <br />
            Un email de confirmation vient de vous être envoyé.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Déclarer un nouvel incident
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
