import gsap from 'gsap';

import { useEffect, useRef } from 'react';
import RegisBot from '../../../assets/regis.png';

const Regis = ({ allDatas, showAnswers, choosenContact }) => {
  const { REACT_APP_URL_MEDIAS } = process.env;
  const regis = useRef(null);
  gsap.defaults({ overwrite: true });

  useEffect(() => {
    console.log(allDatas.organization.img_logo_uri);
  }, []);

  useEffect(() => {
    if (!showAnswers) {
      gsap.to(
        regis.current,

        {
          duration: 0.3,
          scaleX: 1.4,
          scaleY: 1.3,
          rotate: -5,
          ease: 'power1.inOut',
          repeat: -1,
          transformOrigin: 'center bottom',
          yoyo: true
        }
      );
    } else {
      gsap.to(regis.current, {
        duration: 0.5,
        scaleX: 1,
        scaleY: 1,
        rotate: 0,
        ease: 'power1.inOut'
      });
    }
  }, [showAnswers]);

  return (
    <img
      src={
        choosenContact?.avatar_image_uri &&
        choosenContact?.avatar_image_uri !== '' &&
        choosenContact?.avatar_name &&
        choosenContact?.avatar_name !== ''
          ? `${REACT_APP_URL_MEDIAS}${choosenContact?.avatar_image_uri}`
          : RegisBot
      }
      style={
        choosenContact?.avatar_image_uri &&
        choosenContact?.avatar_image_uri !== '' &&
        choosenContact?.avatar_name &&
        choosenContact?.avatar_name !== ''
          ? { borderRadius: '30px', border: '1px solid #CCCCCC' }
          : {}
      }
      alt="Regis"
      ref={regis}
      width={50}
      height={50}
    />
  );
};

export default Regis;
