const SpinnerMessage = ({ showRegis }) => {
  return (
    <span style={{ position: 'relative', top: showRegis ? '22px' : '0px', left: '0px' }}>
      <svg width="34" height="9" viewBox="0 0 34 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4.5" cy="4.5" r="4.5" fill="#B2B2B5" />
        <circle cx="17" cy="4.5" r="4.5" fill="#828285" />
        <circle cx="29.5" cy="4.5" r="4.5" fill="#939396" />
      </svg>
    </span>
  );
};

export default SpinnerMessage;
