import { useEffect, useState } from 'react';
import RegisConversationItem from './RegisConversationItem';
import Answer from './Answer';
import { Button, Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const ChatQuestion = ({
  questionDatas,
  answerTree,
  scrollToBottom,
  validateAnswer,
  setMatterportFrameVisible,
  allDatas,
  setOpenModalUploadFile,
  setOpenModalTutorial,
  tags,
  setTags,
  hasMatterportScan,
  blockAllAnswers,
  setBlockAllAnswers,
  files,
  sendDatas
  // suppressFile
}) => {
  const [currentMessageDisplayed, setCurrentMessageDisplayed] = useState(0);
  const [showAnswers, setShowAnswers] = useState(false);
  const [answersInputs, setAnswersInputs] = useState({});
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [nextQuestionIdToShow, setNextQuestionIdToShow] = useState(null);
  const [questionAnswered, setQuestionAnswered] = useState(false);
  const [dateNow, setDateNow] = useState(new Date());
  const [showRecap, setShowRecap] = useState(false);

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  const [imagesUploaded, setImagesUploaded] = useState([]);

  useEffect(() => {
    initQuestion();
    if (questionDatas.id === 100) {
      setBlockAllAnswers(true);
    }

    return () => {
      initQuestion();
    };
  }, []);

  useEffect(() => {
    if (questionDatas.answers === undefined || questionDatas.answers === null) {
      initQuestion();
    }
  }, [questionDatas]);

  useEffect(() => {
    if (answerTree[answerTree.length - 1].id === 100 && questionDatas.id === 100) {
      setShowRecap(true);
    }
  }, [answerTree]);

  const initQuestion = () => {
    setCurrentMessageDisplayed(0);
    setShowAnswers(false);
    setDateNow(new Date());
    setAnswersInputs({});
    setNextButtonDisabled(true);
    setNextQuestionIdToShow(null);
    setQuestionAnswered(false);

    let responseId =
      questionDatas?.responses[0] && questionDatas?.responses[0].nextQuestion
        ? questionDatas?.responses[0].nextQuestion
        : questionDatas?.nextQuestion;
    let hasUniqueResponseId = true;
    questionDatas.responses.forEach((response, index) => {
      if (response.type === 'input') {
        setAnswersInputs((prev) => {
          return { ...prev, [index]: { isValid: false, value: '' } };
        });
      }
      if (responseId !== response.nextQuestion) {
        hasUniqueResponseId = false;
      }
    });

    if (hasUniqueResponseId) {
      setNextQuestionIdToShow(responseId);
    }
  };

  useEffect(() => {
    // console.log('nextQuestionIdToShow', nextQuestionIdToShow);
  }, [nextQuestionIdToShow]);

  useEffect(() => {
    // console.log('answersInputs', answersInputs);

    if (
      answersInputs &&
      Object.values(answersInputs).every((value) => value['value'] !== '' && value['isValid'] === true)
    ) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [answersInputs]);

  useEffect(() => {
    // console.log('currentMessageDisplayed', currentMessageDisplayed);
    // console.log(questionDatas.questionText.length);

    if (currentMessageDisplayed === questionDatas.questionText.length) {
      // console.log(' ---------------------- end');
      setShowAnswers(true);
    }
  }, [currentMessageDisplayed]);

  useEffect(() => {
    scrollToBottom();
  }, [showAnswers]);

  return (
    <Grid container style={{ pointerEvents: blockAllAnswers && questionDatas.id !== 100 ? 'none' : 'auto' }}>
      {questionDatas?.questionText?.map((question, index) => {
        return (
          <RegisConversationItem
            key={dateNow + index}
            startShowMessage={index <= currentMessageDisplayed ? true : false}
            showRegis={index === 0 ? true : false}
            message={question}
            indexMessage={index}
            setCurrentMessageDisplayed={setCurrentMessageDisplayed}
            scrollToBottom={scrollToBottom}
            answerTree={answerTree}
            showAnswers={showAnswers}
          ></RegisConversationItem>
        );
      })}

      {showRecap && showAnswers && (
        <Grid container spacing={2}>
          <Grid item xs sx={{ marginTop: '20px', textAlign: 'center', width: '100%' }}>
            <div style={{ textAlign: 'center', width: '100%' }}>
              {`${allDatas.property.address.street} ${allDatas.property.address.zip_code} ${allDatas.property.address.city}`}

              {allDatas.property.address_complement && (
                <>
                  <br />
                  {`${allDatas.property.address_complement}`}
                </>
              )}
            </div>
          </Grid>
          {answerTree.map((answer, index) => {
            // console.log('answer', answer);
            if (answer.inRecap !== undefined && answer.inRecap === false) {
              return null;
            } else {
              if (answer.answers && answer.answers.index !== undefined && answer.answers.index !== null) {
                // console.log(answer);
                // console.log(answer.responses);
                // console.log(answer.answers.index);
                // console.log(answer.responses[answer.answers.index]);
                // console.log(answer.responses[answer.answers.index].responseText);
                return (
                  <Grid
                    key={index}
                    xs={12}
                    item
                    style={{
                      paddingTop: '20px',
                      paddingLeft:
                        !hasMatterportScan && matchesSm
                          ? matchesMd
                            ? matchesLg
                              ? matchesXl
                                ? '500px'
                                : '400px'
                              : '300px'
                            : '150px'
                          : matchesXs
                            ? '20px'
                            : '60px',
                      paddingRight:
                        !hasMatterportScan && matchesSm
                          ? matchesMd
                            ? matchesLg
                              ? matchesXl
                                ? '500px'
                                : '400px'
                              : '300px'
                            : '150px'
                          : matchesXs
                            ? '20px'
                            : '60px'
                    }}
                  >
                    {/* <div>{answer.recapText}</div> */}
                    <Button
                      fullWidth
                      variant={'outlined'}
                      color="primary"
                      style={{ borderRadius: '15px', pointerEvents: 'none', fontSize: '15px', borderColor: '#F17633' }}
                    >
                      {answer.responses[answer.answers.index].responseText}
                    </Button>
                  </Grid>
                );
              } else if (index !== 0 && answer.answers) {
                // console.log('answer', answer);
                return (
                  <Grid
                    key={index}
                    xs={12}
                    item
                    style={{
                      paddingTop: '30px',
                      paddingLeft:
                        !hasMatterportScan && matchesSm
                          ? matchesMd
                            ? matchesLg
                              ? matchesXl
                                ? '500px'
                                : '400px'
                              : '300px'
                            : '150px'
                          : matchesXs
                            ? '20px'
                            : '60px',
                      paddingRight:
                        !hasMatterportScan && matchesSm
                          ? matchesMd
                            ? matchesLg
                              ? matchesXl
                                ? '500px'
                                : '400px'
                              : '300px'
                            : '150px'
                          : matchesXs
                            ? '20px'
                            : '60px'
                    }}
                  >
                    <TextField
                      size="small"
                      disabled
                      fullWidth
                      label={answer.responses[0].recapText}
                      value={
                        answer.answers && answer.answers[0] && answer.answers[0]?.value
                          ? answer.answers[0]?.value
                          : null
                      }
                      multiline={answer.responses[0].nbLines && answer.responses[0].nbLines > 1 ? true : false}
                      rows={answer.responses[0].nbLines ? Number(answer.responses[0].nbLines) : 1}
                    ></TextField>
                  </Grid>
                );
              }
            }
          })}

          <Grid item xs style={{ textAlign: 'center', width: '100%' }}>
            <Button>
              <div
                onClick={() => {
                  // console.log('joindre des fichiers');
                  setOpenModalUploadFile(true);
                }}
              >
                <svg width="35" height="38" viewBox="0 0 35 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18.5361 34.8895C14.276 39.0547 7.42621 39.0381 3.18982 34.8313C-1.04535 30.6269 -1.06204 23.8236 3.13284 19.5931L3.12931 19.5907L18.5386 4.28958L20.4656 2.37674C23.6572 -0.792246 28.8304 -0.792246 32.0219 2.37674C35.2134 5.54572 35.2134 10.6833 32.0219 13.8535L15.0359 30.5451L15.03 30.5403C12.9367 32.5445 9.60025 32.5303 7.53191 30.4869C5.46358 28.4423 5.45055 25.1463 7.47845 23.0803L7.47248 23.0731L9.36389 21.2067L18.821 11.8659L20.7112 13.7348L9.36389 24.942C8.319 25.9726 8.319 27.6456 9.36389 28.6773C10.4088 29.709 12.103 29.709 13.1467 28.6773L30.0958 11.9382L30.091 11.9347L30.1552 11.88C32.283 9.76773 32.283 6.34109 30.1552 4.22893C28.0275 2.11677 24.5795 2.11664 22.4518 4.22893L22.3948 4.29311L22.3912 4.29067L20.4654 6.20229L5.05518 21.5035C1.86367 24.6725 1.86367 29.8101 5.05518 32.9791C8.24668 36.1481 13.4212 36.1481 16.6102 32.9791L30.0936 19.5907L32.0195 17.6791L33.9454 19.5907L32.0195 21.5035L18.5386 34.8919L18.5361 34.8895Z"
                    fill="#F17633"
                  />
                </svg>
                <span style={{ position: 'relative', top: '-10px' }}>Joindre des fichiers</span>
              </div>
            </Button>
          </Grid>
          <Grid item xs={12}>
            {imagesUploaded}
          </Grid>
        </Grid>
      )}

      {showAnswers && (
        <Grid
          container
          spacing={2}
          style={{
            paddingTop: '30px',
            paddingLeft:
              !hasMatterportScan && matchesSm
                ? matchesMd
                  ? matchesLg
                    ? matchesXl
                      ? '500px'
                      : '400px'
                    : '300px'
                  : '150px'
                : '60px',
            paddingRight:
              !hasMatterportScan && matchesSm
                ? matchesMd
                  ? matchesLg
                    ? matchesXl
                      ? '500px'
                      : '400px'
                    : '300px'
                  : '150px'
                : '60px'
          }}
        >
          {questionDatas.responses.map((answer, index) => {
            return (
              <Answer
                key={index}
                answerData={answer}
                index={index}
                setAnswersInputs={setAnswersInputs}
                answersInputs={answersInputs}
                questionAnswered={questionAnswered}
                questionDatas={questionDatas}
                validateAnswer={validateAnswer}
                setMatterportFrameVisible={setMatterportFrameVisible}
                setOpenModalTutorial={setOpenModalTutorial}
                tags={tags}
                setTags={setTags}
                blockAllAnswers={blockAllAnswers}
                answerTree={answerTree}
                dateNow={dateNow}
              ></Answer>
            );
          })}
        </Grid>
      )}
      {showAnswers && questionDatas.needValidation && !questionAnswered && (
        <Grid item style={{ textAlign: 'center', width: '100%' }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: '20px', color: 'white' }}
            disabled={nextButtonDisabled}
            onClick={() => {
              if (questionDatas.id === 100) {
                // console.log('Validate and send datas !!!!');
                setQuestionAnswered(true);
                setNextButtonDisabled(true);
                validateAnswer(0, questionDatas.id, answersInputs);

                // setTimeout(() => {
                sendDatas();
                // }, 1000);
              } else {
                setQuestionAnswered(true);
                setNextButtonDisabled(true);
                validateAnswer(0, questionDatas.id, answersInputs);
              }
            }}
          >
            Continuer
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ChatQuestion;
