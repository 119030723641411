import { useEffect, useState } from 'react';
import RegisConversationItem from './RegisConversationItem';
import Answer from './Answer';
import { Button, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileUploader from './FileUploader';

const ChatQuestion = ({
  questionDatas,
  answerTree,
  scrollToBottom,
  validateAnswer,
  setMatterportFrameVisible,
  allDatas,
  setOpenModalUploadFile,
  setOpenModalTutorial,
  tags,
  setTags,
  hasMatterportScan,
  blockAllAnswers,
  blockRemove,
  setBlockAllAnswers,
  files,
  setFiles,
  sendDatas,
  setTagMode,
  setRemoveAllTags,
  setNavigateToTag,
  width,
  choosenContact,
  setBlockRemove
}) => {
  const [currentMessageDisplayed, setCurrentMessageDisplayed] = useState(0);
  const [showAnswers, setShowAnswers] = useState(false);
  const [answersInputs, setAnswersInputs] = useState({});
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [nextQuestionIdToShow, setNextQuestionIdToShow] = useState(null);
  const [questionAnswered, setQuestionAnswered] = useState(false);
  const [dateNow, setDateNow] = useState(new Date());
  const [showRecap, setShowRecap] = useState(false);

  const [finalText, setFinalText] = useState('');

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  const [imagesUploaded, setImagesUploaded] = useState([]);

  useEffect(() => {
    initQuestion();
    if (questionDatas.id === 100) {
      setBlockAllAnswers(true);
    }

    if (questionDatas.id === 101) {
      setBlockRemove(true);
    }

    return () => {
      initQuestion();
    };
  }, []);

  useEffect(() => {
    if (questionDatas.answers === undefined || questionDatas.answers === null) {
      initQuestion();
    }
  }, [questionDatas]);

  useEffect(() => {
    if (answerTree[answerTree.length - 1].id === 100 && questionDatas.id === 100) {
      setShowRecap(true);
    }
  }, [answerTree]);

  const initQuestion = () => {
    setCurrentMessageDisplayed(0);
    setShowAnswers(false);
    setDateNow(new Date());
    setAnswersInputs({});
    setNextButtonDisabled(true);
    setNextQuestionIdToShow(null);
    setQuestionAnswered(false);

    let responseId =
      questionDatas?.responses && questionDatas?.responses[0] && questionDatas?.responses[0]?.nextQuestion
        ? questionDatas?.responses[0]?.nextQuestion
        : questionDatas?.nextQuestion;
    let hasUniqueResponseId = true;
    questionDatas?.responses?.forEach((response, index) => {
      if (response.type === 'input') {
        setAnswersInputs((prev) => {
          return { ...prev, [index]: { isValid: false, value: '' } };
        });
      }
      if (responseId !== response.nextQuestion) {
        hasUniqueResponseId = false;
      }
    });

    if (hasUniqueResponseId) {
      setNextQuestionIdToShow(responseId);
    }
  };

  useEffect(() => {
    // console.log('nextQuestionIdToShow', nextQuestionIdToShow);
  }, [nextQuestionIdToShow]);

  useEffect(() => {
    // console.log('answersInputs', answersInputs);

    if (
      answersInputs &&
      Object.values(answersInputs).every((value) => value['value'] !== '' && value['isValid'] === true)
    ) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [answersInputs]);

  useEffect(() => {
    // console.log('currentMessageDisplayed', currentMessageDisplayed);
    // console.log(questionDatas.questionText.length);

    if (currentMessageDisplayed === questionDatas.questionText.length) {
      // console.log(' ---------------------- end');
      setShowAnswers(true);
    }
  }, [currentMessageDisplayed]);

  useEffect(() => {
    scrollToBottom();
  }, [showAnswers]);

  useEffect(() => {
    if (showRecap === true) {
      let finalText = '';
      console.log(answerTree);
      answerTree.forEach((answer, index) => {
        console.log(answer);
        if (index === 0) {
          finalText = 'Vous déclarez';
        }

        // Reponse à choix

        // Reponse à input
        if (Array.isArray(answer?.answers)) {
        } else if (typeof answer?.answers === 'object' && answer?.answers !== null) {
          if (
            answer?.answers?.index !== null &&
            answer?.answers?.index !== undefined &&
            answer.responses &&
            answer.responses[answer?.answers?.index].recapText
          ) {
            finalText += ` ${answer.responses[answer.answers.index].recapText}`;
          }
          console.log('answers is an object', answer?.answers);

          if (answer?.answers[0] && answer?.answers[0].value && index !== 0) {
            finalText += `. ${answer.responses[0].recapText} : ${answer.answers[0].value}`;
          }
        } else {
          console.log('answers is neither an array nor an object', answer?.answers);
        }
      });

      console.log(finalText);
      setFinalText(finalText);
    }
  }, [showRecap]);

  return (
    <Grid container style={{ pointerEvents: blockAllAnswers && questionDatas.id !== 100 ? 'none' : 'auto' }}>
      {questionDatas?.questionText?.map((question, index) => {
        return (
          <RegisConversationItem
            allDatas={allDatas}
            key={dateNow + index}
            startShowMessage={index <= currentMessageDisplayed ? true : false}
            showRegis={index === 0 ? true : false}
            message={question}
            indexMessage={index}
            setCurrentMessageDisplayed={setCurrentMessageDisplayed}
            scrollToBottom={scrollToBottom}
            answerTree={answerTree}
            showAnswers={showAnswers}
            choosenContact={choosenContact}
          ></RegisConversationItem>
        );
      })}

      {showRecap && showAnswers && (
        <Grid container spacing={2}>
          <Grid item xs sx={{ marginTop: '20px', textAlign: 'center', width: '100%' }}>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <Typography variant="h6" style={{ textAlign: 'center', width: '100%', marginTop: '20px' }}>
                {`${allDatas.property.address.street} ${allDatas.property.address.zip_code} ${allDatas.property.address.city}`}

                {allDatas.property.address_complement && (
                  <>
                    <br />
                    {`${allDatas.property.address_complement}`}
                  </>
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ width: '100%' }}>
            <Typography variant="h6" style={{ textAlign: 'center', width: '100%', marginTop: '20px' }}>
              {finalText}
            </Typography>

            <div>
              <RegisConversationItem
                allDatas={allDatas}
                key={1000}
                startShowMessage={true}
                showRegis={true}
                message={'N’oubliez pas d’ajouter des photos ou des documents pour illustrer la situation !'}
                indexMessage={1000}
                setCurrentMessageDisplayed={setCurrentMessageDisplayed}
                scrollToBottom={scrollToBottom}
                answerTree={answerTree}
                showAnswers={showAnswers}
                choosenContact={choosenContact}
              ></RegisConversationItem>
            </div>
          </Grid>

          <Grid item xs style={{ textAlign: 'center', width: '100%' }}>
            <FileUploader files={files} setFiles={setFiles} blockRemove={blockRemove} />
          </Grid>
          <Grid item xs={12}>
            {imagesUploaded}
          </Grid>
        </Grid>
      )}

      {showAnswers && (
        <Grid
          container
          spacing={2}
          style={{
            paddingTop: '30px',
            paddingLeft:
              !hasMatterportScan && matchesSm
                ? matchesMd
                  ? matchesLg
                    ? matchesXl
                      ? '500px'
                      : '400px'
                    : '300px'
                  : '150px'
                : '60px',
            paddingRight:
              !hasMatterportScan && matchesSm
                ? matchesMd
                  ? matchesLg
                    ? matchesXl
                      ? '500px'
                      : '400px'
                    : '300px'
                  : '150px'
                : '60px'
          }}
        >
          {questionDatas?.responses?.map((answer, index) => {
            return (
              <Answer
                key={index}
                answerData={answer}
                index={index}
                setAnswersInputs={setAnswersInputs}
                answersInputs={answersInputs}
                questionAnswered={questionAnswered}
                questionDatas={questionDatas}
                validateAnswer={validateAnswer}
                setMatterportFrameVisible={setMatterportFrameVisible}
                setOpenModalTutorial={setOpenModalTutorial}
                tags={tags}
                setTags={setTags}
                blockAllAnswers={blockAllAnswers}
                answerTree={answerTree}
                dateNow={dateNow}
                setTagMode={setTagMode}
                setRemoveAllTags={setRemoveAllTags}
                setNavigateToTag={setNavigateToTag}
                width={width}
                setBlockAllAnswers={setBlockAllAnswers}
              ></Answer>
            );
          })}
        </Grid>
      )}
      {((showAnswers && questionDatas.needValidation && !questionAnswered) ||
        (questionDatas.id === 81 && tags.length > 0)) && (
        <Grid item style={{ textAlign: 'center', width: '100%' }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: '20px', color: 'white', fontSize: '15px' }}
            disabled={nextButtonDisabled}
            onClick={() => {
              if (questionDatas.id === 100) {
                // console.log('Validate and send datas !!!!');
                setQuestionAnswered(true);
                setNextButtonDisabled(true);
                validateAnswer(0, questionDatas.id, answersInputs);

                // setTimeout(() => {
                sendDatas();
                // }, 1000);
              } else if (questionDatas.id === 81) {
                setNavigateToTag((prev) => !prev);
                setTagMode(false);
                setMatterportFrameVisible(false);
                setBlockAllAnswers(true);
                setQuestionAnswered(true);
                setNextButtonDisabled(true);
                validateAnswer(0, questionDatas.id, answersInputs);
              } else {
                setQuestionAnswered(true);
                setNextButtonDisabled(true);
                validateAnswer(0, questionDatas.id, answersInputs);
              }
            }}
          >
            Continuer
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ChatQuestion;
