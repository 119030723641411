import { Box, Button, Grid, IconButton, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { PDFViewer } from './PDFViewer';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { api } from '~/api';
import { axiosClient } from '~/api/axiosClient';

const DocumentReader = () => {
  const { idFile } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const passNeeded = searchParams.get('pass');
  const token = searchParams.get('token');
  const [jwt, setJwt] = useState(null);

  const [password, setPassword] = useState(null);

  const [isPdf, setIsPdf] = useState(false);
  const [documentUri, setDocumentUri] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const [isError, setIsError] = useState(false);

  const { REACT_APP_URL_MEDIAS } = process.env;

  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const fetchWithAuthentication = (url, authToken) => {
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${authToken}`);
    return fetch(url, { headers });
  };

  const displayProtectedImage = async (imageUrl, authToken) => {
    // Fetch the image.
    const response = await fetchWithAuthentication(imageUrl, authToken);

    // Create an object URL from the data.
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    setImageSrc(objectUrl);
  };

  useEffect(() => {
    if (searchParams.get('token') && passNeeded !== 'true') {
      api.users
        .getSession({ value: token })
        .then((data) => {
          axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.jwt;
          setJwt(data.jwt);
          api.files.get(idFile).then(async (fileData) => {
            setDocumentUri(fileData.uri);
            if (fileData.uri.includes('.pdf')) {
              setIsPdf(true);
            } else {
              const imageUrl = REACT_APP_URL_MEDIAS + fileData.uri + '?test=true';
              const authToken = data.jwt;
              displayProtectedImage(imageUrl, authToken);
            }
          });
        })
        .catch((err) => {
          setIsError(true);
          setErrorMessage("Vous n'avez pas les droits pour accéder à ce document");
        });
    }
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      {passNeeded === 'true' && (
        <>
          {!documentUri && (
            <Box>
              <TextField
                label="Mot de passe"
                size="small"
                autoComplete="off"
                type={showPassword ? 'text' : 'password'}
                inputProps={{
                  autoComplete: 'off'
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                helperText={isError ? 'Mot de passe incorrect' : null}
                error={isError}
              ></TextField>
              <IconButton
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                sx={{ position: 'absolute', right: 0, top: 0 }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
              <Button
                variant="contained"
                sx={{ ml: '5px' }}
                onClick={() => {
                  api.users
                    .getSession({ value: token, pass: password })
                    .then((data) => {
                      axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.jwt;

                      setJwt(data.jwt);

                      api.files.get(idFile).then(async (fileData) => {
                        setDocumentUri(fileData.uri);
                        if (fileData.uri.includes('.pdf')) {
                          setIsPdf(true);
                        } else {
                          const imageUrl = REACT_APP_URL_MEDIAS + fileData.uri + '?test=true';
                          const authToken = data.jwt;
                          displayProtectedImage(imageUrl, authToken);
                        }
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                      setIsError(true);
                    });
                }}
              >
                Valider
              </Button>
            </Box>
          )}
        </>
      )}

      {isPdf && documentUri && (
        <div style={{ width: '100%', height: 'calc(100vh)' }}>
          <PDFViewer documentData={{ uri: documentUri + '?test=true' }} setNotes={() => {}} notes={[]} token={jwt} />
        </div>
      )}
      {imageSrc && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0px',
            left: '0px',
            backgroundImage: 'url(' + imageSrc + ')',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
      )}
      {errorMessage !== null && <Box>{errorMessage}</Box>}
    </Box>
  );
};

export default DocumentReader;
function getElementById(imageId: any) {
  throw new Error('Function not implemented.');
}
