import gsap from 'gsap';

import React, { useEffect, useRef } from 'react';
import RegisBot from '../../../assets/regis.png';

const Regis = ({ showAnswers }) => {
  const regis = useRef(null);
  gsap.defaults({ overwrite: true });

  useEffect(() => {
    if (!showAnswers) {
      gsap.to(
        regis.current,

        {
          duration: 0.3,
          scaleX: 1.4,
          scaleY: 1.3,
          rotate: -5,
          ease: 'power1.inOut',
          repeat: -1,
          transformOrigin: 'center bottom',
          yoyo: true
        }
      );
    } else {
      gsap.to(regis.current, {
        duration: 0.5,
        scaleX: 1,
        scaleY: 1,
        rotate: 0,
        ease: 'power1.inOut'
      });
    }
  }, [showAnswers]);

  return (
    <img src={RegisBot} alt="Regis" ref={regis} width={50} height={50} />
    // <svg ref={regis} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <g clipPath="url(#clip0_8113_32683)">
    //     <path
    //       d="M17.8805 16.093C17.8805 16.093 17.2346 14.0737 19.8413 14.1128H23.7747C25.4562 14.0737 26.5269 14.6051 26.1952 16.093H17.8805Z"
    //       fill="#D4D6E3"
    //     />
    //     <path d="M22.0293 14.1073V9.5708" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" />
    //     <path
    //       d="M22.0285 9.56512C22.7805 9.56512 23.3901 8.97909 23.3901 8.25619C23.3901 7.53329 22.7805 6.94727 22.0285 6.94727C21.2766 6.94727 20.667 7.53329 20.667 8.25619C20.667 8.97909 21.2766 9.56512 22.0285 9.56512Z"
    //       fill="black"
    //       stroke="black"
    //       strokeWidth="0.5"
    //       strokeMiterlimit="10"
    //     />
    //     <path
    //       opacity="0.5"
    //       d="M21.5352 0V3.91559H22.0352H22.5352V0L22.0352 0L21.5352 0Z"
    //       fill="black"
    //       stroke="black"
    //       strokeMiterlimit="10"
    //       strokeLinecap="round"
    //     />
    //     <path
    //       opacity="0.5"
    //       d="M24.5898 5.3922L26.9871 3.00928"
    //       stroke="black"
    //       strokeWidth="2"
    //       strokeMiterlimit="10"
    //       strokeLinecap="round"
    //     />
    //     <path
    //       opacity="0.5"
    //       d="M19.364 5.36423L16.9609 3.00928"
    //       stroke="black"
    //       strokeWidth="2"
    //       strokeMiterlimit="10"
    //       strokeLinecap="round"
    //     />
    //     <path
    //       d="M15.5006 16.1045L27.9814 16.0317C28.5039 16.0734 29.0115 16.2199 29.4715 16.4618C29.9315 16.7036 30.3337 17.0354 30.6521 17.4358C31.1757 18.1394 31.4137 19.0028 31.3213 19.8634L12.3818 19.8187C12.4326 18.8406 12.8453 17.9121 13.5455 17.2008C14.0795 16.6689 14.7562 16.2895 15.5006 16.1045Z"
    //       fill="#A3A2C4"
    //     />
    //     <path
    //       d="M12.1315 19.9136C9.48435 20.7702 7.19386 22.4223 5.59813 24.626C4.0024 26.8298 3.18602 29.4684 3.26949 32.1523C3.35297 34.8363 4.33187 37.4233 6.06171 39.5314C7.79154 41.6396 10.1806 43.1572 12.8763 43.8602L30.7509 44.0001C33.5277 43.3534 36.0047 41.8434 37.7971 39.7045C39.5896 37.5656 40.5973 34.9176 40.6636 32.1716C40.7299 29.4257 39.8512 26.7355 38.164 24.519C36.4767 22.3024 34.0753 20.6834 31.3327 19.9136H12.1315Z"
    //       fill="#D5D6E1"
    //     />
    //     <path
    //       d="M3.71806 27.4311C3.07311 27.2369 2.37697 27.2727 1.7572 27.5318C1.34389 27.7336 0.985162 28.0253 0.709444 28.3835C0.433726 28.7418 0.2486 29.1569 0.168739 29.5959C0.110553 30.916 0.0581857 32.2305 0 33.5506C0.136567 34.2097 0.506726 34.8028 1.04734 35.2287C1.41835 35.4927 1.8454 35.6747 2.298 35.7617C2.75061 35.8488 3.21762 35.8387 3.6657 35.7322C3.36033 34.201 3.23154 32.6421 3.28167 31.0838C3.31546 29.8563 3.46149 28.634 3.71806 27.4311Z"
    //       fill="#A0A1C1"
    //     />
    //     <path
    //       d="M40.2881 35.6768C40.9326 35.8746 41.63 35.8388 42.2489 35.5761C42.6623 35.3743 43.021 35.0827 43.2967 34.7244C43.5724 34.3661 43.7576 33.9511 43.8374 33.512C43.8898 32.1975 43.948 30.8774 44.0003 29.5629C43.8681 28.9036 43.4995 28.3098 42.9588 27.8848C42.5885 27.6195 42.1616 27.4363 41.7089 27.3483C41.2563 27.2603 40.7889 27.2697 40.3405 27.3758C40.6398 28.9096 40.7685 30.4697 40.7245 32.0297C40.6878 33.2552 40.5418 34.4754 40.2881 35.6768Z"
    //       fill="#A3A1C4"
    //     />
    //     <path
    //       d="M14.3777 22.52C12.2711 22.9705 10.3755 24.0709 8.98006 25.6534C7.58459 27.236 6.76576 29.2138 6.64843 31.2854C6.53109 33.357 7.1217 35.4087 8.33018 37.1275C9.53866 38.8464 11.2987 40.1382 13.342 40.8058L29.6339 40.9681C31.8828 40.5859 33.9198 39.4544 35.3874 37.7722C36.855 36.0901 37.6593 33.9647 37.6593 31.7692C37.6593 29.5737 36.855 27.4484 35.3874 25.7662C33.9198 24.084 31.8828 22.9526 29.6339 22.5704L14.3777 22.52Z"
    //       fill="#A4A3C1"
    //     />
    //     <path
    //       d="M29.1163 23.6727L14.1161 39.7658C12.2889 39.1906 10.7105 38.0514 9.62585 36.5253C8.54124 34.9991 8.01118 33.1714 8.11797 31.3259C8.22477 29.4804 8.96245 27.7205 10.2165 26.3194C11.4705 24.9183 13.1706 23.9545 15.0529 23.5776L28.872 23.628C28.9544 23.6375 29.0361 23.6524 29.1163 23.6727Z"
    //       fill="#343551"
    //     />
    //     <path
    //       d="M36.1501 31.7669C36.1437 33.7346 35.4071 35.6355 34.0727 37.1275C32.7383 38.6195 30.8938 39.6047 28.8711 39.9058L14.1152 39.7659L29.1155 23.6729C31.0875 24.0182 32.8713 25.0172 34.1565 26.496C35.4418 27.9748 36.1472 29.8399 36.1501 31.7669Z"
    //       fill="#25273E"
    //     />
    //     <path
    //       d="M16.0419 29.1599H19.8065C19.8318 28.9076 19.802 28.653 19.7189 28.4125C19.6358 28.172 19.5013 27.9508 19.3241 27.7632C19.1468 27.5756 18.9307 27.4257 18.6897 27.3231C18.4486 27.2205 18.1879 27.1675 17.9242 27.1675C17.6606 27.1675 17.3999 27.2205 17.1588 27.3231C16.9177 27.4257 16.7016 27.5756 16.5244 27.7632C16.3471 27.9508 16.2127 28.172 16.1296 28.4125C16.0465 28.653 16.0166 28.9076 16.0419 29.1599Z"
    //       fill="#F17633"
    //     />
    //     <path
    //       d="M24.2637 29.2325L28.0283 29.1374C28.0538 28.8805 28.0222 28.6213 27.9357 28.377C27.8492 28.1327 27.7097 27.9088 27.5265 27.7202C27.3433 27.5316 27.1205 27.3825 26.8729 27.2827C26.6253 27.183 26.3585 27.135 26.0902 27.1417C25.8218 27.1485 25.558 27.21 25.3162 27.3221C25.0744 27.4341 24.8601 27.5943 24.6875 27.7919C24.5148 27.9895 24.3878 28.2201 24.3147 28.4685C24.2417 28.7168 24.2243 28.9772 24.2637 29.2325Z"
    //       fill="#F17633"
    //     />
    //     <path
    //       d="M16.7871 31.7275H27.1849C27.1662 32.9559 26.6925 34.1375 25.8489 35.0603C25.0053 35.983 23.8471 36.5863 22.5824 36.7619C19.7371 37.0639 16.9268 34.8265 16.7871 31.7275Z"
    //       fill="#F17633"
    //     />
    //     <path
    //       d="M12.3818 19.8185C12.6262 18.9459 14.6453 18.7333 14.6453 18.7333C19.591 18.7333 24.6066 18.683 29.4244 18.7333C29.4244 18.7333 31.3154 19.1752 31.3213 19.8856C25.0081 19.9248 18.695 19.757 12.3818 19.8185Z"
    //       fill="#75759A"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_8113_32683">
    //       <rect width="44" height="44" fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>
  );
};

export default Regis;
