import { axiosPost, axiosGet, axiosPatch, axiosPostMultipart } from '.';

export const createMissionOrder = (data: any) => {
  return axiosPost('/mission-orders', data);
};

export const searchMissionOrder = () => {
  return axiosGet(`/mission-orders`);
};

export const getTechnicians = () => {
  return axiosGet('/mission-orders/technicians');
};

export const getPhotographs = () => {
  return axiosGet('mission-orders/photographs');
};

export const assignMissionOrder = (idOdm: string, idUser: any) => {
  return axiosPost(`/mission-orders/${idOdm}/assign/${idUser}`, {});
};

export const updateMissionOrder = (idOdm: string, data: any) => {
  return axiosPatch(`/mission-orders/${idOdm}`, data);
};

export const odmLink = (idOdm: string, idOtherOdm: string) => {
  return axiosPatch(`/mission-orders/${idOdm}/associate-to-odm/${idOtherOdm}`, {});
};

export const getEdl = (id) => {
  return axiosGet(`public/edls/${id}`);
};

export const patchEDL = (idEdl: string, data: any) => {
  return axiosPatch(`/edls/${idEdl}`, data);
};

export const createRoom = (idEdl: string, data: any) => {
  return axiosPost(`/edls/${idEdl}/rooms`, data);
};

export const createEquipment = (idEdl: string, data: any) => {
  return axiosPost(`/edls/${idEdl}/equipments`, data);
};

export const createEquipmentState = (idEdl: string, idEquipment: string, data: any) => {
  return axiosPost(`/edls/${idEdl}/equipments/${idEquipment}/states`, data);
};

export const attachFileToState = (idEdl: string, data: any) => {
  console.log('AttachFileToState', idEdl, data);
  return axiosPostMultipart(`/edls/${idEdl}/files`, data);
};

export const createEvent = (idEdl: string, idEquipment: string, data: any) => {
  return axiosPost(`/edls/${idEdl}/equipments/${idEquipment}/events`, data);
};

export const createShortLink = (idEdl: string, data: any) => {
  return axiosPost(`/edls/${idEdl}/short-links`, data);
};

export const updateRoom = (idEdl: string, idRoom: string, data: any) => {
  return axiosPatch(`/edls/${idEdl}/rooms/${idRoom}`, data);
};

export const edlDone = (idEdl: string, data: any) => {
  return axiosPatch(`/edls/${idEdl}/edl-done`, data);
};

export const edl3dDone = (idEdl: string, data: any) => {
  return axiosPatch(`/edls/${idEdl}/edl-3d-done`, data);
};

export const attachFileToTag = (idEdl: string, idFile: string, data: any) => {
  return axiosPatch(`/edls/${idEdl}/files/${idFile}`, data);
};

