import { useEffect } from 'react';
import { Worker, LocalizationMap } from '@react-pdf-viewer/core';
import * as React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, DocumentLoadEvent, PdfJs, Position, PrimaryButton, Tooltip, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import {
  HighlightArea,
  highlightPlugin,
  MessageIcon,
  RenderHighlightContentProps,
  RenderHighlightTargetProps,
  RenderHighlightsProps
} from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import fr_FR from '@react-pdf-viewer/locales/lib/fr_FR.json';
// import { getToken } from '~/app/components/Auth/AuthApi';

interface Note {
  id: number;
  content: string;
  highlightAreas: HighlightArea[];
  quote: string;
}

export const PDFViewer = ({ documentData, setNotes, notes, token }) => {
  const [message, setMessage] = React.useState('');
  const notesContainerRef = React.useRef<HTMLDivElement | null>(null);
  let noteId = notes.length;
  const noteEles: Map<number, HTMLElement> = new Map();
  const [currentDoc, setCurrentDoc] = React.useState<PdfJs.PdfDocument | null>(null);
  const { REACT_APP_URL_MEDIAS } = process.env;

  const handleDocumentLoad = (e: DocumentLoadEvent) => {
    setCurrentDoc(e.doc);
    if (currentDoc && currentDoc !== e.doc) {
      setNotes([]);
    }
  };

  useEffect(() => {
    console.log(documentData);
  }, [documentData]);

  const renderHighlightTarget = (props: RenderHighlightTargetProps) => (
    <div
      style={{
        background: '#eee',
        display: 'flex',
        position: 'absolute',
        left: `${props.selectionRegion.left}%`,
        top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        transform: 'translate(0, 8px)',
        zIndex: 1
      }}
    >
      <Tooltip
        position={Position.TopCenter}
        target={
          <Button onClick={props.toggle}>
            <MessageIcon />
          </Button>
        }
        content={() => <div style={{ width: '100px' }}>Ajouter une annotation</div>}
        offset={{ left: 0, top: -8 }}
      />
    </div>
  );

  const renderHighlightContent = (props: RenderHighlightContentProps) => {
    const addNote = () => {
      if (message !== '') {
        const note: Note = {
          id: ++noteId,
          content: message,
          highlightAreas: props.highlightAreas,
          quote: props.selectedText
        };
        setNotes(notes.concat([note]));
        props.cancel();
      }
    };

    return (
      <div
        style={{
          background: '#fff',
          border: '1px solid rgba(0, 0, 0, .3)',
          borderRadius: '2px',
          padding: '8px',
          position: 'absolute',
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          zIndex: 1,
          height: '100%'
        }}
      >
        <div>
          <textarea
            rows={3}
            style={{
              border: '1px solid rgba(0, 0, 0, .3)'
            }}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '8px'
          }}
        >
          <div style={{ marginRight: '8px' }}>
            <PrimaryButton onClick={addNote}>Ajouter</PrimaryButton>
          </div>
          <Button onClick={props.cancel}>Annuler</Button>
        </div>
      </div>
    );
  };

  const jumpToNote = (note: Note) => {
    activateTab(3);
    const notesContainer = notesContainerRef.current;
    if (noteEles.has(note.id) && notesContainer) {
      notesContainer.scrollTop = noteEles.get(note.id).getBoundingClientRect().top;
    }
  };

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {notes.map((note) => (
        <React.Fragment key={note.id}>
          {note.highlightAreas
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                style={Object.assign(
                  {},
                  {
                    background: 'yellow',
                    opacity: 0.4
                  },
                  props.getCssProperties(area, props.rotation)
                )}
                onClick={() => {
                  jumpToNote(note);
                }}
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
    renderHighlights
  });

  const { jumpToHighlightArea } = highlightPluginInstance;

  React.useEffect(() => {
    return () => {
      noteEles.clear();
    };
  }, []);

  useEffect(() => {
    if (documentData !== undefined && documentData !== null) {
      if (documentData.annotation !== undefined && documentData.annotation !== null) {
        setNotes(documentData.annotation.notes);
      }
    }
  }, [documentData]);

  const sidebarNotes = (
    <div
      ref={notesContainerRef}
      style={{
        overflow: 'auto',
        width: '100%'
      }}
    >
      {notes.length === 0 && <div style={{ textAlign: 'center' }}>Aucune annotations</div>}
      {notes.map((note, index) => {
        return (
          <div
            key={note.id}
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, .3)',
              cursor: 'pointer',
              padding: '8px'
            }}
            onClick={() => jumpToHighlightArea(note.highlightAreas[0])}
            ref={(ref): void => {
              noteEles.set(note.id, ref as HTMLElement);
            }}
          >
            <blockquote
              style={{
                borderLeft: '2px solid rgba(0, 0, 0, 0.2)',
                fontSize: '.75rem',
                lineHeight: 1.5,
                margin: '0 0 8px 0',
                paddingLeft: '8px',
                textAlign: 'justify'
              }}
            >
              {note.quote}
            </blockquote>
            {note.content}
            <DeleteOutlineIcon
              style={{ right: '0px', position: 'absolute' }}
              onClick={(note) => {
                const newNotes = notes.filter((note, i) => i !== index);
                setNotes(newNotes);
              }}
            >
              delete Note
            </DeleteOutlineIcon>
          </div>
        );
      })}
    </div>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) =>
      defaultTabs.concat({
        content: sidebarNotes,
        icon: <MessageIcon />,
        title: 'Notes'
      })
  });
  const { activateTab } = defaultLayoutPluginInstance;
  return (
    <Worker workerUrl={`${window.location.origin}/pdfWorker/pdf.worker.min.js`}>
      <Viewer
        onDocumentLoad={handleDocumentLoad}
        plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
        fileUrl={REACT_APP_URL_MEDIAS + documentData.uri}
        localization={fr_FR as unknown as LocalizationMap}
        httpHeaders={{
          Authorization: 'Bearer ' + token
        }}
        // withCredentials={true}
      />
    </Worker>
  );
};
