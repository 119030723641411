import { useEffect, useRef, useState } from 'react';
import Regis from './Regis';
import SpinnerMessage from './SpinnerMessage';
import { Grid } from '@mui/material';

const RegisConversationItem = ({
  message,
  showRegis,
  startShowMessage,
  indexMessage,
  setCurrentMessageDisplayed,
  scrollToBottom,
  answerTree,
  showAnswers
}) => {
  const timeoutSpinnerDisplay = useRef<NodeJS.Timeout | undefined>();
  const intervalTypingLetter = useRef<NodeJS.Timeout | undefined>();

  const [showSpinner, setShowSpinner] = useState(true);
  const [currentMessageDisplayedTxt, setCurrentMessageDisplayedTxt] = useState('');
  const [splittedMessage, setSplittedMessage] = useState([]);
  const [idSplitDisplayed, setIdSplitDisplayed] = useState(0);
  const [validatedMsg, setValidatedMsg] = useState(
    message.replace(
      'XXX',
      answerTree && answerTree[0] && answerTree[0]?.answers && answerTree[0]?.answers[0]
        ? answerTree[0]?.answers[0]?.value
        : ''
    )
  );

  useEffect(() => {
    if (startShowMessage) {
      timeoutSpinnerDisplay.current = setTimeout(() => {
        setShowSpinner(false);
      }, 500);

      setSplittedMessage(Array.from(validatedMsg));
    }
  }, [startShowMessage]);

  useEffect(() => {
    // console.log(splittedMessage);
  }, [splittedMessage]);

  useEffect(() => {
    if (showSpinner === false && startShowMessage === true) {
      // console.log(splittedMessage.length);
      setIdSplitDisplayed(0);
      setCurrentMessageDisplayedTxt(indexMessage);
      let count = 1;
      intervalTypingLetter.current = setInterval(() => {
        setIdSplitDisplayed(count);
        scrollToBottom();
        count++;
      }, 15);
    }
  }, [showSpinner]);

  useEffect(() => {
    if (splittedMessage.length < idSplitDisplayed) {
      // console.log('clearInterval');
      clearInterval(intervalTypingLetter.current);
      setCurrentMessageDisplayed(indexMessage + 1);
    }

    let msg = '';

    splittedMessage.forEach((value, index) => {
      if (index <= idSplitDisplayed) {
        msg = msg + value;
      }
    });

    setCurrentMessageDisplayedTxt(msg);
  }, [idSplitDisplayed]);

  if (!startShowMessage) {
    return null;
  } else {
    return (
      <Grid container>
        <Grid item style={{ width: '60px' }}>
          {showRegis ? <Regis showAnswers={showAnswers}></Regis> : null}
        </Grid>
        <Grid item xs>
          {showSpinner ? (
            <SpinnerMessage showRegis={showRegis}></SpinnerMessage>
          ) : (
            <div
              style={{
                width: 'fit-content',
                padding: '10px',
                marginTop: showRegis ? '30px' : '8px',
                marginRight: '30%',
                gap: '10px',
                background: '#152944',
                borderRadius: '4px 24px 24px 24px',
                color: 'white'
              }}
            >
              {currentMessageDisplayedTxt}
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
};

export default RegisConversationItem;
