import { Button, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { MuiTelInput } from 'mui-tel-input';
import _ from 'lodash';

const Answer = ({
  answerData,
  setAnswersInputs,
  answersInputs,
  index,
  questionAnswered,
  questionDatas,
  validateAnswer,
  setMatterportFrameVisible,
  setOpenModalTutorial,
  tags,
  setTags,
  blockAllAnswers,
  answerTree,
  dateNow,
  setTagMode,
  setRemoveAllTags,
  setNavigateToTag,
  width,
  setBlockAllAnswers
}) => {
  const [fileDowloaded, setFileDowloaded] = useState(false);
  const [answerTimeStamp, setAnswerTimeStamp] = useState(null);

  const dowloadFile = (url) => {
    // console.log(window.location);
    const link = document.createElement('a');
    link.href = window.location.origin + '/' + url;
    link.download = 'constat.pdf';
    link.target = '_blank';
    link.addEventListener('click', () => {
      // console.log('File downloaded');
      setFileDowloaded(true);
    });
    link.click();
  };

  useEffect(() => {
    // console.log(answerData);

    setAnswerTimeStamp(dateNow);

    if (answerData.template === 'text' && answerData.minLength === 0) {
      setAnswersInputs((prev) => {
        return { ...prev, [index]: { isValid: true, value: ' ' } };
      });
    }
    return () => {
      setAnswersInputs(null);
    };
  }, []);

  useEffect(() => {
    if (
      answersInputs &&
      answerTimeStamp === dateNow &&
      !questionDatas.needValidation &&
      !_.isEmpty(answersInputs) &&
      answersInputs.index !== undefined &&
      answersInputs.index !== null
    ) {
      validateAnswer(answersInputs.index, questionDatas.id, answersInputs);
    }
  }, [answersInputs]);

  useEffect(() => {}, [questionAnswered]);

  return (
    <Grid item xs={12} style={{ textAlign: 'center' }}>
      {answerData.type === 'input' && (
        <>
          {answerData.template === 'phone_number' ? (
            <MuiTelInput
              label={answerData.responseText}
              size="small"
              autoComplete="off"
              error={!answersInputs[index]['isValid'] && answersInputs[index]['value'].length > 3}
              fullWidth
              value={answersInputs[index]['value']}
              onChange={(value, info) => {
                const unformatedPhoneNumber = info.numberValue.replace(/[^0-9]/g, '');
                if (unformatedPhoneNumber.length > 10) {
                  setAnswersInputs((prev) => {
                    return { ...prev, [index]: { isValid: true, value: info.numberValue } };
                  });
                } else {
                  setAnswersInputs((prev) => {
                    return { ...prev, [index]: { isValid: false, value: info.numberValue } };
                  });
                }
              }}
              disabled={questionAnswered}
              defaultCountry="FR"
              forceCallingCode
              // disableDropdown={true}
              continents={['EU']}
              helperText={
                !answersInputs[index]['isValid'] && answersInputs[index]['value'].length > 3 ? 'numéro incorrect' : ''
              }
            />
          ) : (
            <TextField
              label={answerData.responseText}
              size="small"
              autoComplete="off"
              error={
                answersInputs &&
                answersInputs[index] &&
                !answersInputs[index]['isValid'] &&
                answersInputs[index]['value'] !== ''
              }
              fullWidth
              value={
                answersInputs && answersInputs[index] && answersInputs[index]['value']
                  ? answersInputs[index]['value']
                  : ''
              }
              disabled={questionAnswered}
              multiline={answerData.nbLines && answerData.nbLines > 1 ? true : false}
              rows={answerData.nbLines ? Number(answerData.nbLines) : 1}
              onChange={(e) => {
                if (answerData.template === 'email') {
                  const re = /\S+@\S+\.\S+/;
                  if (re.test(e.target.value)) {
                    setAnswersInputs((prev) => {
                      return { ...prev, [index]: { isValid: true, value: e.target.value } };
                    });
                  } else {
                    setAnswersInputs((prev) => {
                      return { ...prev, [index]: { isValid: false, value: e.target.value } };
                    });
                  }
                } else if (answerData.template === 'text') {
                  if (e.target.value.length >= Number(answerData.minLength)) {
                    setAnswersInputs((prev) => {
                      return { ...prev, [index]: { isValid: true, value: e.target.value } };
                    });
                  } else {
                    setAnswersInputs((prev) => {
                      return { ...prev, [index]: { isValid: false, value: e.target.value } };
                    });
                  }
                }
              }}
              helperText={
                answersInputs &&
                answersInputs[index] &&
                !answersInputs[index]['isValid'] &&
                answersInputs[index]['value'] !== ''
                  ? answerData.template === 'email'
                    ? 'email incorrect'
                    : '3 caractères minimum'
                  : ''
              }
              type={answerData.template === 'email' ? 'email' : 'text'}
            ></TextField>
          )}
        </>
      )}
      {answerData.type === 'button' && (
        <>
          {/* {answerData.template === 'recapTags' && (
            <div style={{ paddingBottom: '10px' }}>
              <>{tags.length > 0 && <>{`Vous avez saisi ${tags.length} tag${tags.length > 1 ? 's' : ''}`} </>}</>
            </div>
          )} */}
          {answerData.template !== 'recapTags' && (
            <Button
              variant={answersInputs?.index === index ? 'contained' : 'outlined'}
              color={answersInputs?.index === index ? 'secondary' : 'primary'}
              style={{
                borderRadius: '15px',
                fontSize: '15px',
                pointerEvents:
                  (blockAllAnswers && questionDatas?.id !== 101) || answersInputs?.index === index ? 'none' : 'auto',
                display:
                  blockAllAnswers && (answerData.template === 'reposTags' || answerData.template === 'recapTags')
                    ? 'none'
                    : 'block'
              }}
              disabled={blockAllAnswers && questionDatas?.id !== 101}
              fullWidth
              onClick={(e) => {
                if (answerData.template && answerData.template === 'quit') {
                  console.log('QUIT');
                  window.close();
                  return;
                }
                if (answerData.template && answerData.template === 'link' && answerData.url && answerData.url !== '') {
                  dowloadFile(answerData.url);
                } else if (answerData.template && answerData.template === 'matterport') {
                  setTagMode(true);
                  setMatterportFrameVisible(true);
                  setOpenModalTutorial(true);
                } else if (answerData.template && answerData.template === 'removeTags') {
                  setTagMode(false);
                  setTags([]);
                } else if (answerData.template && answerData.template === 'recapTags') {
                  setNavigateToTag((prev) => !prev);
                  setTagMode(false);
                  setMatterportFrameVisible(false);
                  setBlockAllAnswers(true);
                } else if (answerData.template && answerData.template === 'reposTags') {
                  setNavigateToTag((prev) => !prev);
                  setMatterportFrameVisible(true);
                } else if (answerData.template && answerData.template === 'quit') {
                  window.document.close();
                } else {
                  setRemoveAllTags((prev) => !prev);
                  setTagMode(false);
                }
                setAnswersInputs((prev) => {
                  return { ...prev, index };
                });
              }}
              sx={{
                color: answersInputs?.index === index ? 'white' : null,
                pointerEvents: answersInputs?.index === index ? 'none' : 'auto',
                fontSize: '15px'
              }}
            >
              {width !== window.innerWidth && answerData.template === 'recapTags' ? 'Suivant' : answerData.responseText}
            </Button>
          )}

          {fileDowloaded && (
            <div>
              <p>
                Le fichier a été téléchargé, s'il y a eu un problème vous pouvez tenter de le retélécharger en cliquant{' '}
                <a
                  href={'#'}
                  onClick={() => {
                    if (
                      answerData.template &&
                      answerData.template === 'link' &&
                      answerData.url &&
                      answerData.url !== ''
                    ) {
                      dowloadFile(answerData.url);
                    }
                  }}
                >
                  ici
                </a>
              </p>
            </div>
          )}
        </>
      )}
    </Grid>
  );
};

export default Answer;
